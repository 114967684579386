/* VideoToursPage.css */

.design-banner {
    background-color: rgb(131, 207, 202);
    color: #f7f7f3;
    padding: 10px;
    text-align: center;
    font-size: 30px;
    width: 100%;
    margin-bottom: 30px;
}

.video-tours-page {
    padding: 20px;
    max-width: 100%;
}

.video-tours-page h1 {
    color: #f7f7f3;
    text-align: center;
    margin-bottom: 20px;
}

.video-upload-form {
    margin-bottom: 30px;
    text-align: center;
}

.video-upload-form input[type="text"],
.video-upload-form textarea,
.video-upload-form input[type="file"] {
    margin: 10px 0;
    padding: 8px;
    width: 80%;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.video-upload-form button {
    background-color: #f7f7f3;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.video-upload-form button:hover {
    background-color: darken(#f7f7f3, 10%);
}

.videos-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.video-item {
    border: 2px solid rgb(131, 207, 202);
    border-radius: 8px;
    padding: 15px;
    max-width: 100%;
}

.video-item h2,
.video-item p {
    text-align: center;
    color: #f7f7f3;
}

.video-item video {
    width: 100%;
    height: auto;
    border-radius: 4px;
}

.video-item button {
    display: block;
    width: 100%;
    padding: 8px;
    background-color: #f7f7f3;
    color: white;
    border: none;
    border-radius: 4px;
    margin-top: 10px;
    cursor: pointer;
}

.video-item button:hover {
    background-color: darken(#f7f7f3, 10%);
}

/* Schedule Section Styles */
.schedule-section {
    background-color: rgb(131, 207, 202);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    width: 100%;
    /* Ensure it covers the full width */
}

.schedule-section p {
    color: #f7f7f3;
    margin: 0;
    padding: 5px;
}

.schedule-button {
    background-color: transparent;
    color: #f7f7f3;
    padding: 10px 15px;
    border: 2px solid #f7f7f3;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, padding 0.3s;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    white-space: normal;
}

.schedule-button:hover {
    background-color: #f7f7f3;
}