.image-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.image-modal-content {
    padding: 20px;
    background: white;
    border-radius: 8px;
    width: 80%;
    /* Increase width */
    max-width: 1200px;
    /* Set a max-width if needed */
    position: relative;
    display: flex;
    justify-content: center;
}

.image-modal-content img {
    max-width: 100%;
    /* Ensure image is responsive within modal */
    max-height: 80vh;
    /* Adjust max height to be responsive */
    object-fit: contain;
    /* Use contain to ensure the image is fully visible */
}
.image-modal-content button {
    position: absolute;
    top: 10px;
    right: 10px;
}