/* General Styles */
.homepage-container {
    width: 100vw;
    display: contain;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0;
}

.homepage-image {
    width: 100%;
    /* Full width */
    height: auto;
    /* Maintain aspect ratio */
    object-fit: contain;
    /* Ensure the image is fully visible without cropping */
}

/* Remove excess white space on mobile */
@media (max-width: 800px) {
    .homepage-container {
        min-height: auto;
        height: auto;
        padding: 0;
        /* Remove any excess padding */
    }
}

/* Fade-in effect container */
/* .fade-in-container {
    position: relative;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 5px;
    border-radius: 5px;
    animation: fade-in 120s ease-in-out;
    text-align: center;
} */

/* Main text styles */
.homepage-title {
    font-family: cursive;
    margin-top: 75px;
}

/* Reflected text effect */
/* .reflection {
    position: absolute;
    top: calc(100% - 90px);
    transform: scaleY(-1);
    opacity: 0.2;
    filter: blur(5px);
    animation: brighten 120s ease-in-out forwards;
    width: 100%;
} */

/* Add a gradient to fade out the reflection */
/* .reflection-mask {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(transparent, rgba(255, 255, 255, 0.05));
    pointer-events: none;
} */

/* Animation for brightening the reflection */
/* @keyframes brighten {
    0% {
        opacity: 0.2;
        filter: blur(10px);
    }

    25% {
        opacity: 0.3;
        filter: blur(8px);
    }

    50% {
        opacity: 0.4;
        filter: blur(6px);
    }

    75% {
        opacity: 0.5;
        filter: blur(4px);
    }

    100% {
        opacity: 0.6;
        filter: blur(2px);
    }
} */

.homepage-title {
    font-family: cursive;
    margin-top: 75px;
}

.homepage-subtitle {
    font-family: cursive;
    margin-top: -20px;
}

.testimonials-section {
    margin: 20px;
    padding: 20px;
    background-color: rgb(247, 247, 243);
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
}

.testimonials-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.testimonial {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.testimonial:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.testimonial p {
    margin: 5px 0;
    color: rgb(50, 50, 50);
    
}

.testimonial strong {
    color: rgb(18, 174, 174);
    font-weight: bold;
}

.testimonial-form {
    margin-top: 30px;
}

.testimonial-form button {
    background-color: rgb(131, 207, 202);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.testimonial-form button:hover {
    background-color: rgb(18, 174, 174);
}

.testimonial-form input,
.testimonial-form textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.testimonial-form input:focus,
.testimonial-form textarea:focus {
    border-color: rgb(18, 174, 174);
    outline: none;
}

/* Responsive font sizes */
@media (max-width: 767px) {
    .homepage-title {
        font-size: 8vw;
    }

    .homepage-subtitle {
        font-size: 6vw;
    }

    .schedule-section p,
    .schedule-section button {
        font-size: 5vw;
    }
}

@media (min-width: 768px) {
    .homepage-title {
        font-size: 50px;
    }

    .homepage-subtitle {
        font-size: 40px;
    }

    .schedule-section p,
    .schedule-section button {
        font-size: 30px;
        max-width: 400px;
    }
}

/* Schedule Section Styles */
.schedule-section {
    background-color: rgb(131, 207, 202);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    width: 100%;
    position: relative;
    margin-top: 0px;
}

.schedule-section p {
    color: #f7f7f3;
    margin: 0;
    padding: 5px;
}

.schedule-button {
    background-color: transparent;
    color: #f7f7f3;
    padding: 10px 15px;
    border: 2px solid #f7f7f3;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, padding 0.3s;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    white-space: normal;
}

.schedule-button:hover {
    background-color: #f7f7f3;
    color: rgb(131, 207, 202);
}

.schedule-button.small-button {
    font-size: 14px;
    padding: 8px 12px;
    max-width: 200px;
    margin: 10px auto;
}

.admin-dashboard-button {
    margin-top: 10px;
    display: inline-block;
    text-decoration: none;
    color: #fff;
    background-color: #007bff;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.admin-dashboard-button:hover {
    background-color: #0056b3;
}

.schedule-button.small-button {
    font-size: 14px;
    padding: 8px 12px;
    max-width: 200px;
    margin: 10px auto;
}

/* Store Image Styles */
.store-image-container {
    display: none;
    /* Hide by default */
    margin-top: 20px;
    text-align: center;
}

.store-image {
    width: 100%;
    max-width: 300px;
    /* Adjust size to fit well on mobile */
    height: auto;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.store-image:hover {
    transform: scale(1.05);
    /* Slight zoom on hover */
}

/* Show store image only on mobile */
@media (max-width: 768px) {
    .store-image-container {
        display: block;
    }
}

.store-callout {
    font-size: 22px;
    color: rgb(131, 207, 202);
    /* Brand colors */
    text-align: center;
    margin-bottom: 5px;
    font-weight: bold;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    /* Adds a boutique-like feel */
}

.store-subtext {
    font-size: 18px;
    color: #f7f7f3;
    text-align: center;
    margin-bottom: 20px;
    font-style: italic;
}


