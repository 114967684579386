.real-estate-banner {
  background-color: rgb(131, 207, 202);
  color: #f7f7f3;
  padding: 10px;
  text-align: center;
  font-size: 30px;
  width: 100%;
  margin-top: 5px;
}

.real-estate-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 0;
  /* Remove padding */
  margin: 0;
  /* Remove margin */
}

.selected-listing {
  width: 100%;
  max-width: 1200px;
  margin: 20px 0;
  padding: 20px;
  border: 2px solid #f7f7f3;
  border-radius: 5px;
  background-color: #f1f1f1;
}

.listing-images {
  margin-bottom: 20px;
}

.featured-image {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.thumbnail-gallery {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  overflow-x: auto;
}

.thumbnail-image {
  cursor: pointer;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.listing-thumbnails {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.listing-thumbnail {
  cursor: pointer;
  width: 200px;
  border: 2px solid rgb(131, 207, 202);
  border-radius: 5px;
  padding: 10px;
}

.listing-thumbnail img {
  max-width: 100%;
  height: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.modal-content img {
  width: 100%;
  height: auto;
  max-height: 80vh;
}

.modal-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.modal-controls button {
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
}

.modal-controls button:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

/* Create Listing Modal Styling */
.create-listing-modal {
  width: 100%;
  max-width: 600px;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
}

.create-listing-modal input,
.create-listing-modal textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.create-listing-modal button {
  width: 100%;
  padding: 10px;
  background-color: rgb(131, 207, 202);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.create-listing-modal button:hover {
  background-color: rgb(18, 174, 174);
}

/* Request to Schedule Button */
.request-showing-btn {
  display: block;
  text-align: center;
  margin: 20px auto 0;
  padding: 15px 25px;
  background-color: rgb(131, 207, 202);
  color: #f7f7f3;
  border-radius: 5px;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 250px;
}

.request-showing-btn:hover {
  background-color: rgb(18, 174, 174);
  transform: translateY(-3px);
}

/* Additional styles for responsive design */
@media (max-width: 768px) {
  .featured-image {
    max-height: 300px;
  }

  .thumbnail-image {
    width: 80px;
    height: 80px;
  }
}

.listing-info-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.listing-info-item {
  flex: 1 1 45%;
  /* Adjust as needed for spacing */
  margin-bottom: 10px;
}

.listing-info-item strong {
  font-weight: bold;
}

.listing-details {
  margin-top: 20px;
}

.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.preview-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.preview-images img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.preview-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.preview-images img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.create-listing-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: rgb(131, 207, 202);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.create-listing-btn:hover {
  background-color: rgb(18, 174, 174);
}

.modal-content.create-listing-modal {
  max-height: 80vh;
  /* Adjust this value as needed */
  overflow-y: auto;
  padding-bottom: 20px;
  /* Ensure some padding at the bottom */
}