.fullscreen-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    /* Ensure a clean background around the image */
    z-index: 9999;
}

.fullscreen-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    /* Ensures the entire image is visible */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    /* Optional shadow to add depth */
}

.close-button {
    position: fixed;
    top: 20px;
    right: 20px;
    font-size: 30px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    z-index: 10000;
}

.close-button:hover {
    color: red;
}