.admin-dashboard {
    padding: 20px;
    background-color: #f7f7f3;
}

.admin-dashboard h1 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.admin-dashboard section {
    margin-bottom: 30px;
}

.admin-dashboard ul {
    list-style: none;
    padding: 0;
}

.admin-dashboard li {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.admin-dashboard button {
    padding: 10px 15px;
    background-color: rgb(131, 207, 202);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.admin-dashboard button:hover {
    background-color: rgb(18, 174, 174);
}