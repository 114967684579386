/* AboutUsPage.css */

.about-us-container {
    max-width: 1500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.about-us-item {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1500px;
    gap: 20px;
    flex-direction: column;
}

.about-us-item img {
    flex: 1;
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    object-fit: cover;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.edit-description-textarea {
    width: 100%;
    height: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    resize: vertical;
}

.about-us-description {
    flex: 1;
    text-align: left;
    font-size: 16px;
    color: #333;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0;
    max-width: 100%;
    word-wrap: break-word;
}

@media (min-width: 768px) {
    .about-us-item {
        flex-direction: row;
    }

    .about-us-item:nth-child(even) {
        flex-direction: row-reverse;
    }

    .about-us-item img {
        max-width: 400px;
    }
}

/* Styles for the Schedule section */
.schedule-section {
    background-color: rgb(131, 207, 202);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    padding: 20px;
    text-align: center;
}

.schedule-section p {
    color: #f7f7f3;
    margin-bottom: 10px;
    margin: 0;
    padding: 5px;
}

.schedule-section button {
    background-color: transparent;
    color: #f7f7f3;
    padding: 10px 15px;
    border: 2px solid #f7f7f3;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 auto;
    white-space: normal;
    transition: background-color 0.3s, padding 0.3s;
    width: 100%;
    max-width: 300px;
}

/* Responsive font sizes */
@media (max-width: 767px) {
    .schedule-section p {
        font-size: 5vw;
        /* Responsive font size for mobile */
    }

    .schedule-section button {
        font-size: 5vw;
        /* Responsive font size for mobile */
    }
}

@media (min-width: 768px) {
    .schedule-section p {
        font-size: 30px;
        /* Adjusted font size for desktop */
    }

    .schedule-section button {
        font-size: 30px;
        /* Adjusted font size for desktop */
        max-width: 400px;
        /* Adjust button width for desktop */
    }
}


