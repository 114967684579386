/* General Styles for the Navbar and Dropdown */
.teal-navbar {
  background-color: #f7f7f3;
  display: flex;
  justify-content: space-between;
  padding: 0px;
  flex-wrap: wrap;
}

.teal-navbar-left,
.teal-navbar-right {
  display: flex;
  flex-wrap: wrap;
}

.maroon-link {
  color: rgb(18, 174, 174);
  margin-right: 10px;
}

.cream-navbar {
  background-color: rgb(131, 207, 202);
  display: flex;
  justify-content: space-between;
  padding: 10px;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.cream-navbar-left,
.cream-navbar-right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

/* Added margin to ensure uniform spacing between buttons */
.cream-navbar-right {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* Add uniform margin to navbar links */
.cream-navbar-link,
.shop-link-container,
.dropdown {
  margin: 0 15px;
}

.navbar-logo {
  height: 140px;
  width: auto;
  margin-right: 15px;
}

.cream-navbar-link {
  margin-right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/* Store Button Styles */
.shop-link {
  text-decoration: none;
  font-weight: bold;
  color: #fff;
  background-color: rgb(18, 174, 174);
  padding: 10px 15px;
  /* Adjust padding to match other links */
  border-radius: 5px;
  /* Softer edges */
  cursor: pointer;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  /* Reduce shadow */
  transition: all 0.3s ease;
  /* Smooth transition */
}

.shop-link:hover {
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.2);
  /* Slightly enlarge shadow */
  transform: scale(1.05);
  /* Slight zoom on hover */
}

/* Dropdown styles */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  background-color: rgb(131, 207, 202);
  min-width: 150px;
  padding: 5px 0;
  z-index: 1;
  display: none;
}

.dropdown-item {
  display: block;
  padding: 5px 20px;
  color: black;
  transition: all 0.3s ease;
}

/* Show dropdown on hover */
.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown .dropdown-item:hover {
  background-color: rgb(131, 207, 202);
  /* Background color change on hover */
  color: #ffffff;
  /* Text color change on hover */
  font-size: 20px;
  /* Increase font size */
  font-weight: bold;
  /* Make it bolder */
  text-shadow: 1px 1px 5px rgba(255, 255, 255, 0.6);
  /* Brighten effect */
}

.big-link {
  font-size: 18px;
}

.social-link-space {
  margin-right: 10px;
}

/* Social media icons */
.social-links svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.x-icon {
  /* Default styles for desktop */
  width: 20px;
  height: 20px;
  vertical-align: middle;
  position: relative;
  top: 0px;
}

/* Responsive adjustments for mobile devices */
@media (max-width: 768px) {

  .teal-navbar-left,
  .cream-navbar-right,
  .teal-navbar-right {
    flex-direction: column;
    align-items: flex-start;
  }

  /* Adjust icon size and spacing on mobile */
  .social-links svg {
    margin-top: 10px;
    width: 25px;
    height: 25px;
  }

  .x-icon {
    width: 25px;
    height: 25px;
    position: relative;
    top: 0px;
  }
  .maroon-link,
  .cream-navbar-link {
    margin: 5px 0;
  }

  .navbar-logo {
    height: 160px;
    margin-right: 0;
  }

  .cream-navbar-right {
    align-items: center;
  }

  .dropdown-menu {
    min-width: 100%;
  }

  .dropdown-item {
    padding: 10px;
  }

  .cream-navbar {
    flex-direction: column;
    align-items: center;
  }

  .cream-navbar-left,
  .cream-navbar-right {
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }

  .cream-navbar-right {
    margin-top: 5px;
  }
}

@keyframes fallingConfetti {
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes popUp {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
